<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Leave Applications
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your leave applications</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View leave applications of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all leave applications</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu1"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="From (Date)"
                v-model="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="primary"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="To (Date)"
                v-model="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="primary"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="reviewStatus"
            :items="reviewStatuses"
            label="Status"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="type"
            :items="types"
            label="Leave Types"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Staff"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-btn depressed block color="green" dark @click="exportCSV">
            <v-icon class="me-2"> mdi-file-export-outline </v-icon> export csv
          </v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
      id="imso-log-table"
        mobile-breakpoint="0"
        :headers="headers"
        :items="items.data"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="items.totalCount"
        :loading="loading"
        loading-text="Loading..."
      >
        <template v-slot:item.review_status="{ item }">
          <v-chip
            small
            dark
            :color="
              item.review_status == 'APPROVED'
                ? 'green'
                : item.review_status == 'REJECTED'
                ? 'error'
                : 'amber darken-2'
            "
            >{{
              item.review_status == "APPROVED"
                ? "Approved"
                : item.review_status == "REJECTED"
                ? "Rejected"
                : "Pending"
            }}</v-chip
          >
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchLeaveApplications } from "@/services/leaveApplicationsService";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    limit: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    page: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    type: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    reviewStatus: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    staffUuids: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    tab: 2,
    dataLoading: false,
    loading: false,
    headers: [
      {
        text: "Application Id",
        value: "id",
        sortable: false,
      },
      {
        text: "Date",
        value: "from_date",
        sortable: false,
      },
      {
        text: "Staff",
        value: "staff.first_name",
        sortable: false,
      },
      {
        text: "No of days",
        value: "no_of_days",
        sortable: false,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
      },
      { text: "Status", value: "review_status", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
    page: 1,
    limit: 10,
    startDate: "",
    endDate: "",
    staffUuids: [],
    type: [],
    reviewStatus: [],
    staff: [],
    types: ["ANNUAL", "CASUAL", "SICK", "LIEU", "NOPAY"],
    reviewStatuses: ["APPROVED", "REJECTED", "PENDING"],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Leave Applications",
        "Read All"
      )
    ) {
      this.dataLoading = true;
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      await this.fetchApplications();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewLeaveApplication",
        params: { uuid: item.uuid },
        query: { bc: item.id, mylog: "false" },
      });
    },
    newItem() {
      this.$router.push({
        name: "PageNewLeaveApplication",
        query: { overrule: "true" },
      });
    },
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyLeaveApplicationList",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubLeaveApplicationList",
        });
      }
    },
    async fetchApplications() {
      this.loading = true;
      this.items = await fetchLeaveApplications(
        this.page,
        this.limit,
        this.startDate ? this.startDate : "",
        this.endDate ? this.endDate : "",
        JSON.stringify(this.staffUuids),
        JSON.stringify(this.reviewStatus),
        JSON.stringify(this.type)
      );
      this.loading = false;
    },
    exportCSV() {
      const tableIds = ["imso-log-table"];
      const filename = "Leave Applications";

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
  },
};
</script>