<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Leave Applications
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your leave applications</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View leave applications of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all leave applications</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu1"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="From (Date)"
                v-model="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="primary"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="To (Date)"
                v-model="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="primary"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="reviewStatus"
            :items="reviewStatuses"
            label="Status"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="type"
            :items="types"
            label="Leave Types"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Staff"
            outlined
            dense
            clearable
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.name }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ staffUuids.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items.data"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="items.totalCount"
        :loading="loading"
        loading-text="Loading..."
      >
        <template v-slot:item.review_status="{ item }">
          <v-chip
            small
            dark
            :color="
              item.review_status == 'APPROVED'
                ? 'green'
                : item.review_status == 'REJECTED'
                ? 'error'
                : 'amber darken-2'
            "
            >{{
              item.review_status == "APPROVED"
                ? "Approved"
                : item.review_status == "REJECTED"
                ? "Rejected"
                : "Pending"
            }}</v-chip
          >
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchLeaveApplications } from "@/services/leaveApplicationsService";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    limit: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    page: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    type: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    reviewStatus: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    staffUuids: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    tab: 3,
    dataLoading: false,
    loading: false,
    headers: [
      {
        text: "Application Id",
        value: "id",
        sortable: false,
      },
      {
        text: "Date",
        value: "from_date",
        sortable: false,
      },
      {
        text: "Staff",
        value: "staff.first_name",
        sortable: false,
      },
      {
        text: "No of days",
        value: "no_of_days",
        sortable: false,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
      },
      { text: "Status", value: "review_status", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
    page: 1,
    limit: 10,
    startDate: "",
    endDate: "",
    staffUuids: [],
    type: [],
    reviewStatus: [],
    staff: [],
    types: ["ANNUAL", "CASUAL", "SICK", "LIEU", "NOPAY"],
    reviewStatuses: ["APPROVED", "REJECTED", "PENDING"],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Leave Applications",
        "Read Sub"
      )
    ) {
      this.dataLoading = true;
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      await this.fetchApplications();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewLeaveApplication",
        params: { uuid: item.uuid },
        query: { bc: item.id, mylog: "false" },
      });
    },
    newItem() {
      this.$router.push({
        name: "PageNewLeaveApplication",
        query: { overrule: "true" },
      });
    },
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyLeaveApplicationList",
        });
      }
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllLeaveApplicationList",
        });
      }
    },
    async fetchApplications() {
      this.loading = true;
      if (this.$_getSubordinates(this.staff, this.auth.uuid).length > 0) {
        if (this.staffUuids.length == 0) {
          this.staffUuids = this.$_getSubordinates(this.staff, this.auth.uuid);
          const filteredArray = this.staff.filter((item) =>
            this.staffUuids.includes(item.uuid)
          );
          this.staff = filteredArray;
        }
      } else {
        this.staff = [];
      }

      if (this.staffUuids.length > 0) {
        this.items = await fetchLeaveApplications(
          this.page,
          this.limit,
          this.startDate ? this.startDate : "",
          this.endDate ? this.endDate : "",
          JSON.stringify(this.staffUuids),
          JSON.stringify(this.reviewStatus),
          JSON.stringify(this.type)
        );
      } else {
        this.items = [];
      }
      this.loading = false;
    },
  },
};
</script>